import { useRoutes, useNavigate, useLocation } from "react-router-dom";
import NotFound from "../views/pages/sessions/NotFound";
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import { useDispatch ,useSelector} from "react-redux";
import { loggin } from "store/login";
import { useEffect, useState } from "react";
import APIKit from "config/APIKit";
import { storeData } from "store/permissionReducer";

export default function ThemeRoutes() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const key = localStorage.getItem("accessToken");
  const roleId = localStorage.getItem("roleId");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (key) {
          const res = await APIKit.get("/me");
          if (res.status) {
            dispatch(
              loggin({
                token: key,
                user: {
                  me: res.data,
                },
                detail:{
                  details : res.user
                }

              })
            );
            const targetPath = pathname === '/login' ? '/dashboard' : pathname;
            navigate(targetPath || '/dashboard');
          }
          const result = await APIKit.get(`permission/${roleId}`);

          const { data } = result;
          dispatch(storeData(data));
        } else {
          navigate("/login");
          localStorage.clear();
        }
      } catch (err) {
        console.error(err);
        navigate("/login");
        localStorage.clear();
      }
    };

    fetchData();
  }, [key]);
const storedData = useSelector((state) => state.permission.storedData);
  const data = storedData?.filter((item) => item?.read||item?.write).map((item)=> item?.Feature?.name)
  let FilterRoutes =[]
  if(data){
     FilterRoutes = {
      ...MainRoutes,
      children: MainRoutes.children
        .filter((item) => data?.includes(item.title))
        .map((item) => {
          if (item.children) {
            return {
              ...item,
              children: item?.children?.filter((child) =>
                data?.includes(child.title)
              ),
            };
          }
          return item;
        }),
    };
    
}
const routes = data ? FilterRoutes:  MainRoutes
  return useRoutes([
    routes,
    AuthenticationRoutes,
    { path: "*", element: <NotFound /> },
  ]);
}
